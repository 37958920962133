body {
  font-family: "Baufra Alphary", Arial, Helvetica, sans-serif;
  background-color: #ebe8df;
}

h1 {
  font-size: 40px;
  font-family: "Baufra Alphary", Arial, Helvetica, sans-serif;
}

h2 {
  font-size: 32px;
  font-family: "Baufra Alphary", Arial, Helvetica, sans-serif;
}

h3 {
  font-size: 24px;
  font-family: "Baufra Alphary Medium", Arial, Helvetica, sans-serif;
}

.container {
  width: 50%;
  padding-left: 25%;
  padding-right: 25%;
  justify-content: center;
  align-items: center;
}

.pl-1 {
  padding-left: 1.2rem;
}
